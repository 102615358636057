$(() => {
    let iframe = $('#videoModalId iframe');
    let showreelIframe = $('#showreelModalId iframe');
    $('#videoModalId').on('hide.bs.modal', function (event) {
        iframe.attr('src', iframe.attr('src'));
    });
    $('#showreelModalId').on('hide.bs.modal', function (event) {
        showreelIframe.attr('src', showreelIframe.attr('src'));
    });



    $('#videoModalId').on('show.bs.modal', function (event) {
        let button = $(event.relatedTarget)  ;

        let title = button.data('title');
        let rul = button.data('url');
        iframe.attr('src', rul);
        let modal = $(this);
        modal.find('.modal-title').text( title);
    });
});
